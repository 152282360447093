/* src/components/Navigation.css */
.navigation {
    background-color: #007bff;
    padding: 15px;
  }
  
  ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    padding: 0;
    margin: 0;
  }
  
  li {
    margin-right: 10px;
  }
  
  a {
    text-decoration: none;
    color:black;
  }
  
  button {
    background-color: #dc3545;
    color: #ffffff;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #c82333;
  }
  