/* src/components/ThreadDetail.css */
.thread-detail-container {
  max-width: 600px;
  margin: 20px auto;
}

.messages-container {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
  background-color: #f2f3f5;
  border-radius: 8px;
  margin-bottom: 10px;
}

.message-item {
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.message-item p {
  margin: 0;
}

.create-message-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-message-input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  margin-right: 10px;
}

.create-message-button {
  padding: 8px 16px;
  background-color: #1f8ceb;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
