/* src/components/Login.css */
.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .content-container {
    max-width: 400px;
    padding: 20px;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .login-heading {
    color: #333;
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .error-message {
    color: #ff0000;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    text-align: left;
    margin-bottom: 5px;
  }
  
  input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    background-color: #007bff;
    color:#007bff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .register-link {
    color: #0056b3;
    margin-top: 15px;
  }