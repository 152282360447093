/* src/components/Home.css */
.home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .content-container {
    max-width: 600px;
    padding: 20px;
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .welcome-heading {
    color: #333;
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 20px;
  }
  
  .get-started,
  .explore {
    font-size: 1em;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .get-started:hover,
  .explore:hover {
    text-decoration: underline;
  }
  
  /* Add more styles as needed */
  