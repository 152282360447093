/* src/components/Threads.css */

.threads-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .threads-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }
  
  .thread-item {
    width: calc(50% - 10px); /* Розділіть на два рядки та залиште проміжок 10px */
    border: 1px solid #ccc;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .thread-item:hover {
    background-color: #f0f0f0;
  }
  
  .create-thread-button {
    margin: 20px auto; /* Розташувати по центру під списком тредів */
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
  }
  
  .create-thread-button:hover {
    background-color: #45a049;
  }
  